let facebook = document.getElementById('facebook')
let instagram = document.getElementById('instagram')
let github = document.getElementById('github')
var coll = document.getElementsByClassName('collapsible')

facebook.addEventListener('click', () => {
  openInNewTab('https://www.facebook.com/carl.einarsson')
})

instagram.addEventListener('click', () => {
  openInNewTab('https://www.instagram.com/carleinarsson/')
})

github.addEventListener('click', () => {
  openInNewTab('https://github.com/cccarle')
})

const openInNewTab = (url) => {
  var win = window.open(url, '_blank')
  win.focus()
}

$(function () {
  $('.toggle').on('click', function () {
    if ($('.item').hasClass('active')) {
      $('.item').removeClass('active')
      $(this).find('a').html("<i class='fas fa-bars'></i>")
    } else {
      $('.item').addClass('active')
      $(this).find('a').html("<i class='fas fa-times'></i>")
    }
  })
})

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function (event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault()
        $('html, body').animate(
          {
            scrollTop: target.offset().top,
          },
          1000,
          function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target)
            $target.focus()
            if ($target.is(':focus')) {
              // Checking if the target was focused
              return false
            } else {
              $target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
              $target.focus() // Set focus again
            }
          }
        )
      }
    }
  })
